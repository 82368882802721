import styled from 'styled-components'

const Textarea = styled.textarea`
  background-color: transparent;
  border: 1px solid ${({ theme: { color } }) => color.text};
  color: ${({ theme: { color } }) => color.text};

  display: block;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  border-radius: 8px;
  padding: 5px 10px;
  height: ${({ height }) => height ? `${height}px` : '175px'};
  box-sizing: border-box;
  outline: none;
  &:focus {
    border: 1px solid ${({ theme: { color } }) => color.secondary.main}
  }
`
export default Textarea
