import React from 'react'

import Form from '../Form'

import { Container } from './styles'

const Contact = () => (
  <Container>
    <Form
      title="Contact"
      emailSubject="Prise de contact"
      withDevis={false}
      messageIsRequired
    />
  </Container>
)

export default Contact
