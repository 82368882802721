import styled from 'styled-components'

const Input = styled.input`
  background-color: transparent;
  border: 1px solid ${({ theme: { color } }) => color.text};
  color: ${({ theme: { color } }) => color.text};

  display: block;
  font-size: 16px;
  border-radius: 8px;
  padding: 5px 10px;
  height: 35px;
  box-sizing: border-box;
  outline: none;
  &:focus {
    border: 1px solid ${({ theme: { color } }) => color.secondary.main}
  }
`

export default Input
