import React from 'react'
import Layout from '../Layout'
import SEO from '../SEO'

import ContactContent from '../components/Contact'

const Contact = () => (
  <Layout withPadding={false}>
    <SEO title="Contact" keywords={['contact', 'contact form']} />
    <ContactContent />
  </Layout>
)

export default Contact
