import React from 'react'
import styled from 'styled-components'
import { bool, node, func } from 'prop-types'

import { Close } from '../Icons'

const Container = styled.div`
  display: ${({ $show }) => $show ? 'block' : 'none'};
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition: all 3000ms;
`

const Content = styled.div`
  border-radius: 4px;
  background-color: ${({ theme: { color } }) => color.primary.main};
  width: 500px;
  max-width: calc(100vw - 40px);
  margin: 50px auto 0px;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 5px;
`

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 300ms;
  &:hover {
    background-color: rgba(250, 250, 250, 0.1);
  }
`

const CloseIcon = styled(Close)`
  font-size: 25px !important;
  cursor: pointer;
`

const Dialog = ({ children, show, onClose }) => {
  const handleClose = e => {
    e.stopPropagation()
    onClose()
  }
  return (
    <Container onClick={() => { onClose() }} $show={show}>
      <Content onClick={e => { e.stopPropagation() }}>
        <Header>
          <IconWrapper>
            <CloseIcon onClick={handleClose} />
          </IconWrapper>
        </Header>
        {children}
      </Content>
    </Container>
  )
}

Dialog.propTypes = {
  children: node.isRequired,
  show: bool.isRequired,
  onClose: func.isRequired,
}

export default Dialog
