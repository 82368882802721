import styled from 'styled-components'

export const Title = styled.div`
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  color: ${({ theme: { color } }) => color.secondary.main};
  margin-bottom: 20px;
`

export const FormStyled = styled.form`
  padding: 0 15px;
`

export const HiddenLabel = styled.label`
  display: none;
`

export const Line = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
`

export const Label = styled.label`
  padding: 0px 0px 10px 5px;
  color: ${({ theme: { color } }) => color.secondary.main};
`

export const Message = styled.div`
  padding: 10px 15px;
  text-align: center;
  position: relative;
  bottom: 16px;
  opacity: 0.6;
  max-width: 340px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: ${({ $state, theme: { color } }) => $state === 'error'
    ? color.red
    : 'green'};
`
