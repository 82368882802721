import React, { useState } from 'react'
import { string, bool } from 'prop-types'

import Button from '../Button'
import Input from '../Input'
import Textarea from '../Textarea'

import {
  Title, FormStyled, HiddenLabel, Line, Label, Message,
} from './styles'

// eslint-disable-next-line max-len
const sucessTxt = 'Email envoyé avec succès. Nous vous contacterons dans le meilleur délai possible.'
const errorTxt = "Une erreur s'est produite, veuillez réessayer."
const ERROR = 'error'
const SUCCES = 'success'

const Form = ({
  title,
  emailSubject,
  devis,
  withDevis,
  messageIsRequired,
}) => {
  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(null)

  const sanitizeSubject = emailSubject.trim().split(' ').join('-')
  const formId = `form-${sanitizeSubject}`

  const asyncHideMessage = () => {
    setTimeout(() => {
      setShowMessage(null)
    }, 3500)
  }

  const onSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    setShowMessage(null)

    const form = document.getElementById(formId)

    if (!form) {
      console.error(`Cannot find form with id: "${formId}"`)
      setShowMessage(ERROR)
      asyncHideMessage()
      setLoading(false)
      return
    }

    try {
      const formData = new FormData(form)

      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })

      setLoading(false)
      setShowMessage(SUCCES)
      asyncHideMessage()
    } catch (error) {
      setShowMessage(ERROR)
      asyncHideMessage()
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <>
      <FormStyled
        id={formId}
        onSubmit={onSubmit}
        name={emailSubject}
        data-netlify="true"
        netlify-honeypot="safe"
      >
        <input type="hidden" name="form-name" value={emailSubject} />
        <HiddenLabel htmlFor="check-stuff">
          Please leave empty this field, it&apos;s just for securuty ceck
          <input id="check-stuff" name="safe" />
        </HiddenLabel>
        <Title>{title}</Title>
        <Line>
          <Label htmlFor="name">
            Nom :
          </Label>
          <Input
            required
            autoFocus
            placeholder="Votre nom (requis)"
            id="name"
            type="text"
            name="name"
          />
        </Line>
        <Line>
          <Label htmlFor="email">
            Email :
          </Label>
          <Input
            placeholder="Votre mail (requis)"
            id="email"
            required
            type="email"
            name="email"
          />
        </Line>
        <Line>
          <Label htmlFor="message">
            Message :
          </Label>
          <Textarea
            placeholder={`Votre message${messageIsRequired ? ' (requis)' : ''}`}
            required={messageIsRequired}
            height={100}
            id="message"
            name="message"
          />
        </Line>
        {withDevis && (
        <Line>
          <Label htmlFor="devis">
            Resumé de votre devis :
          </Label>
          <Textarea id="devis" readOnly name="devis" value={devis} />
        </Line>
        )}
        <Line>
          <Button loading={loading} type="submit">ENVOYER</Button>
        </Line>
      </FormStyled>
      {
      showMessage && (
        <Message $state={showMessage}>
          {
            showMessage === SUCCES ? sucessTxt : errorTxt
          }
        </Message>
      )
    }
    </>
  )
}

Form.defaultProps = {
  devis: '',
  messageIsRequired: false,
}

Form.propTypes = {
  title: string.isRequired,
  emailSubject: string.isRequired,
  withDevis: bool.isRequired,
  messageIsRequired: bool,
  devis: string,
}

export default Form
export { default as Dialog } from './Dialog'
export { genDevis } from './helpers'
