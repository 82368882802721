export const genDevis = (title, data, totalAmount, text = []) => {
  let devis = `Devis ${title} \n`

  data.forEach(item => {
    let value = null

    if (typeof item.value === 'number') {
      value = `${item.value || 'Non definit'}`
    } else if (typeof item.value === 'string') {
      value = item.value || 'Non definit'
    } else if (typeof item.value === 'boolean') {
      value = item.value ? 'Oui' : 'Non'
    } else {
      value = 'Non definit'
    }

    devis = `${devis}
    ${item.label} :
      ${value}
    `
  })

  const moreOnAmount = text.join(`
  `)

  const more = text?.length > 0
    ? `
    À rajouter au devis :
    ${moreOnAmount}
    `
    : ''

  devis = `
    ${devis}
    Total du Devis : ${typeof totalAmount === 'number'
    ? `${totalAmount}€`
    : totalAmount}
    ${more}
  `

  return devis
}

/**
const data = [
  {,
    label: 'Quantité',
    value: 100,
  },
  {
    label: 'Fair trade',
    value: true,
  }
]
 */
